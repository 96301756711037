/* eslint-disable */

import { cloneDeep } from 'lodash'

// base64加密解密
const base64EncodeChars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
const base64DecodeChars = new Array(
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  62,
  -1,
  -1,
  -1,
  63,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  -1,
  -1,
  -1,
  -1,
  -1,
  -1,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  -1,
  -1,
  -1,
  -1,
  -1
)

function base64encode(str) {
  let out
  let i
  let c1
  let c2
  let c3
  const len = str.length
  i = 0
  out = ''
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2)
      out += base64EncodeChars.charAt((c1 & 0x3) << 4)
      out += '=='
      break
    }
    c2 = str.charCodeAt(i++)
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2)
      out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
      out += base64EncodeChars.charAt((c2 & 0xf) << 2)
      out += '='
      break
    }
    c3 = str.charCodeAt(i++)
    out += base64EncodeChars.charAt(c1 >> 2)
    out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
    out += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
    out += base64EncodeChars.charAt(c3 & 0x3f)
  }
  return out
}

function base64decode(str) {
  str = str.replace(/\s+/g, '+')
  let c1
  let c2
  let c3
  let c4
  let i
  let len
  let out
  len = str.length
  i = 0
  out = ''
  while (i < len) {
    /* c1 */
    do {
      c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff]
    } while (i < len && c1 == -1)
    if (c1 == -1) {
      break
    }
    /* c2 */
    do {
      c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff]
    } while (i < len && c2 == -1)
    if (c2 == -1) {
      break
    }
    out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4))
    /* c3 */
    do {
      c3 = str.charCodeAt(i++) & 0xff
      if (c3 == 61) {
        return out
      }
      c3 = base64DecodeChars[c3]
    } while (i < len && c3 == -1)
    if (c3 == -1) {
      break
    }
    out += String.fromCharCode(((c2 & 0xf) << 4) | ((c3 & 0x3c) >> 2))
    /* c4 */
    do {
      c4 = str.charCodeAt(i++) & 0xff
      if (c4 == 61) {
        return out
      }
      c4 = base64DecodeChars[c4]
    } while (i < len && c4 == -1)
    if (c4 == -1) {
      break
    }
    out += String.fromCharCode(((c3 & 0x03) << 6) | c4)
  }
  return out
}

function utf16to8(str) {
  let out
  let i
  let len
  let c
  out = ''
  len = str.length
  for (i = 0; i < len; i++) {
    c = str.charCodeAt(i)
    if (c >= 0x0001 && c <= 0x007f) {
      out += str.charAt(i)
    } else if (c > 0x07ff) {
      out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f))
      out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f))
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f))
    } else {
      out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f))
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f))
    }
  }
  return out
}

function utf8to16(str) {
  let out
  let i
  let len
  let c
  let char2
  let char3
  out = ''
  len = str.length
  i = 0
  while (i < len) {
    c = str.charCodeAt(i++)
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += str.charAt(i - 1)
        break
      case 12:
      case 13:
        // 110x xxxx　 10xx xxxx
        char2 = str.charCodeAt(i++)
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
        break
      case 14:
        // 1110 xxxx　10xx xxxx　10xx xxxx
        char2 = str.charCodeAt(i++)
        char3 = str.charCodeAt(i++)
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        )
        break
    }
  }
  return out
}

function base64Encode(str) {
  return base64encode(utf16to8(str))
}

function base64Decode(str) {
  return utf8to16(base64decode(str))
}

const getChartParams = function getChartParams(picData) {
  return {
    // 统计字段
    sumList: (picData.sumField || []).reduce((list, curr) => {
      list.push(curr.alias || curr.name)
      return list
    }, []),
    // 分组字段
    groupList: (picData.xData || []).reduce((list, curr) => {
      list.push(curr.alias || curr.name)
      return list
    }, []),
    // 数据
    sumData: picData.data || [],
    // 是否显示值
    valShow: picData.sumData?.valShow,
    groupField: picData.groupField || [],
  }
}

const getSumFieldObj = function getSumFieldObj(fields) {
  const obj = (fields || []).reduce((obj, curr, currIdx) => {
    obj[currIdx] = curr.type
    return obj
  }, {})
  return obj
}

const fmoney = function fmoney(num, n) {
  // n = n > 0 && n <= 20 ? n : 2;
  const ss = num.toFixed(n).toString()
  const l = ss.split('.')[0].split('').reverse()
  const r = ss.split('.')[1] || ''
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  return (t.split('').reverse().join('') + (r ? `.${r}` : '')).replace(
    '-,',
    '-'
  )
}

const getFormatValue = function getFormatValue(idx, a, sf) {
  const obj = getSumFieldObj(sf)
  const fi = sf[idx]
  const f = obj[idx] || ''
  if (`${f}` === '4' || `${f}` === '3' || `${f}` === '100') {
    // 数值和金额公式字段一同处理
    let _name = ''
    const numberFormat = fi.numberFormat || 1 // 数值/百分比
    const dec =
      typeof fi.reserverDecimal === 'undefined' ? 2 : fi.reserverDecimal // 小数位数
    const permillage = typeof fi.permillage === 'undefined' ? 1 : fi.permillage // 数值千分位 0显示 1不显示
    if (`${numberFormat}` === '1') {
      if (`${permillage}` === '0') {
        _name = fmoney(Number(a.data), dec)
      } else {
        _name = Number(a.data).toFixed(dec)
      }
    } else if (`${numberFormat}` === '2') {
      _name = `${Number(a.data * 100).toFixed(dec)}%`
    }
    return _name
  }
  return a.data
}

// 手工处理百分比图表数据
function c1416(series, flg) {
  // flg:true,只计算前n-1项
  series = cloneDeep(series || [])

  const result = []
  const obj = series.reduce((obj, curr, idx) => {
    if (flg && idx === series.length - 1) {
      return obj
    }
    obj[idx] = curr.data || []
    return obj
  }, {})
  Object.keys(obj).forEach((key) => {
    const o = obj[key]
    if (Array.isArray(o)) {
      o.forEach((val, idx) => {
        if (result[idx] === undefined || result[idx] === null) {
          result[idx] = 0
        }
        result[idx] = Math.abs(result[idx]) + Math.abs(val)
      })
    } else if (o !== null && typeof o === 'object') {
      Object.keys(o).forEach((idx) => {
        const val = o[idx]
        if (result[idx] === null || result[idx] === undefined) {
          result[idx] = 0
        }
        result[idx] = Math.abs(result[idx]) + Math.abs(val)
      })
    }
  })
  return result
}

function c1416Tol(index, total) {
  const m = total.find((val, idx) => idx === index) || 0

  return m
}

function done(p) {
  if (!p) return
  ;(p.sumData || []).forEach((val) => {
    if (val.value < 0) {
      val.value = 0
    }
  })
}

function getPiePercent(arr, val) {
  let total = 0
  total = arr.reduce((total, curr) => {
    total += Number(curr.value || 0)
    return total
  }, 0)
  if (total === 0) {
    return 0
  }
  return parseFloat((val * 100) / total).toFixed(2)
}

//凭证日期控件格式
//{ "1": "yyyy-mm", "2": "yyyy/mm", "3": "yyyy mm", "4": "yyyy/mm/dd", "5": "yyyy/mm/dd hh:ii", "6": "yyyy mm dd", "7": "yyyy mm dd hh:ii", "8": "yyyy-mm-dd", "9": "yyyy-mm-dd hh:ii", "10": "yyyy-mm-dd hh:ii:ss", "11": "yyyy-mm-dd hh" }
//后端返回日期 格式化成前端显示 2017-09-11 09:19:00  1-9
//
function getDateAfter(dateString, format) {
  if (!dateString || dateString == ',' || typeof dateString != 'string')
    return ''
  if (!format) return dateString
  var res = ''
  if (format == '1') {
    var arr = dateString.split('-')
    res = arr[0] + '-' + arr[1]
  } else if (format == '2') {
    var arr = dateString.split('-')
    res = arr[0] + '/' + arr[1]
  } else if (format == '3') {
    var arr = dateString.split('-')
    res = arr[0] + ' ' + arr[1]
  } else if (format == '4') {
    var arr = dateString.split(' ')
    res = arr[0].replace(/-/g, '/')
  } else if (format == '5') {
    var arr = dateString.split(' ')
    if (!arr[1]) {
      arr[1] = '00:00'
    }
    var d = arr[0].replace(/-/g, '/'),
      harr = arr[1].split(':')
    res = d + ' ' + harr[0] + ':' + harr[1]
  } else if (format == '6') {
    var arr = dateString.split(' ')
    res = arr[0].replace(/-/g, ' ')
  } else if (format == '7') {
    var arr = dateString.split(' ')
    if (!arr[1]) {
      arr[1] = '00:00'
    }
    var d = arr[0].replace(/-/g, ' '),
      harr = arr[1].split(':')
    res = d + ' ' + harr[0] + ':' + harr[1]
  } else if (format == '8' || format == '000') {
    var arr = dateString.split(' ')
    res = arr[0]
  } else if (format == '9' || format == '001') {
    var arr = dateString.split(' ')
    if (!arr[1]) {
      arr[1] = '00:00'
    }
    var harr = arr[1].split(':')
    res = arr[0] + ' ' + harr[0] + ':' + harr[1]
  } else if (format == '10') {
    res = dateString
  } else if (format == '11') {
    var arr = dateString.split(' ')
    if (!arr[1]) {
      arr[1] = '00:00'
    }
    var harr = arr[1].split(':')
    res = arr[0] + ' ' + harr[0]
  } else if (format == '12') {
    var arr = dateString.split('-')
    res = arr[0]
  }
  return res
}

function getNowTime(dd) {
  var d = dd || new Date(),
    m = '00' + (d.getMonth() + 1),
    r = '00' + d.getDate(),
    h = '00' + d.getHours(),
    M = '00' + d.getMinutes(),
    s = '00' + d.getSeconds(),
    day =
      d.getFullYear() +
      '-' +
      m.substr(m.length - 2) +
      '-' +
      r.substr(r.length - 2),
    time =
      h.substr(h.length - 2) +
      ':' +
      M.substr(M.length - 2) +
      ':' +
      s.substr(s.length - 2)
  return day + ' ' + time
}

const objEquals = (a, b) => {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b
  if (a === null || a === undefined || b === null || b === undefined)
    return false
  if (a.prototype !== b.prototype) return false
  let keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => objEquals(a[k], b[k]))
}

function unique(arr) {
  return arr.reduce((acc, item) => {
    if (!acc.some((itm) => objEquals(itm, item))) {
      acc.push(item)
    }
    return acc
  }, [])
}
// 时间格式3,6,7类型前端需要空格，后台是不需要空格的，这里处理下
function df367(dateString, format) {
  var str = dateString
  if (format == 3 || format == 6) {
    str = dateString.replace(/\s+/g, '')
  } else if (format == 7) {
    var arr = dateString.split(' ')
    str = arr[0] + arr[1] + arr[2] + ' ' + arr[3]
  }
  return str
}
// v4.5 zjh 根据seriesName 来取 y 轴的设置
function getPicFieldsetData(arr, seriesName) {
  let setData = { showFormat: {} }
  arr.forEach((item) => {
    if (seriesName == item.name) {
      setData = cloneDeep(item)
    }
  })
  return setData
}
const exportData = (response) => {
  // const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
  const blob = new Blob([response.data], {
    type: `${response.headers['content-type']}`,
  })
  const el = document.createElement('a')
  const href = window.URL.createObjectURL(blob)
  const fileName =
    `${
      response.headers['content-disposition'] ||
      response.headers['Content-disposition'] ||
      ''
    }`.split('filename=')[1] || '未命名'
  el.href = href
  el.download = decodeURI(fileName)
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
  window.URL.revokeObjectURL(href)
}
function safariDate(date, format) {
  var vendor = navigator.vendor
  if (vendor.indexOf('Apple') > -1) {
    if (typeof date === 'number') {
      date = formatDate(new Date(date), format)
    }
    return date.replace(/\-/g, '/')
  } else {
    return date
  }
}

export {
  getChartParams,
  getSumFieldObj,
  fmoney,
  getDateAfter,
  getFormatValue,
  getNowTime,
  c1416,
  c1416Tol,
  done,
  getPiePercent,
  base64Decode,
  base64Encode,
  unique,
  objEquals,
  df367,
  getPicFieldsetData,
  exportData,
  safariDate,
}
